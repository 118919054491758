/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Fullmap, FullmapWrap, Image, Button, Text, Title, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"La Kapo Barbershop"}>
        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"gvagkt918ph"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap className="--style2" name={"4tbscrqd7gw"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="css-nub37h css-6qqax8 --parallax pb--12 pt--40" name={"csw5apquixc"} layout={"l8"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=3000x_.jpg);
    }
  
background-position: 30% 49%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim6 --center el--3 --full flex--center" anim={"6"} style={{"maxWidth":1090}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/7b3f68dc512c44ad9dac678bb3eb6c73_s=660x_.png"} sizes="(max-width: 959px) 100vw, 33vw" srcSet={"https://cdn.swbpg.com/t/19803/7b3f68dc512c44ad9dac678bb3eb6c73_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/7b3f68dc512c44ad9dac678bb3eb6c73_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/7b3f68dc512c44ad9dac678bb3eb6c73_s=860x_.png 860w"}>
              </Image>

              <Button className="btn-box btn-box--hvr4 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--center fs--26 swpf--uppercase" url={"https://www.instagram.com/la_kapo_barber/"} href={"https://www.instagram.com/la_kapo_barber/"} content={"<span style=\"color: var(--white);\"><span style=\"font-weight: bold;\">instagram</span></span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--center fs--26 swpf--uppercase" url={"https://www.facebook.com/lakapobarber"} href={"https://www.facebook.com/lakapobarber"} content={"<span style=\"color: var(--white); font-weight: bold;\">facebook</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr5 btn-box--cbtn1 btn-box--pbtn5 btn-box--shape4 btn-box--cColor1 btn-box--shadow5 btn-box--filling4 ff--3 fs--26 w--500 swpf--uppercase" content={"<span style=\"color: var(--white); font-weight: bold;\">Rezervace</span>"} url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"mhn835omt6l"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--center fs--14 swpf--uppercase" content={"<span style=\"color: var(--white); font-weight: bold;\">Jsme tradiční pánské holičství, které vytváří luxusní prostor pro opravdové gentlemany. Nechte se hýčkat v rukou profesionálních kadeřníků a unikněte od starostí každodenního života. Přijďte se nechat upravit a užít si trochu relaxu.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-mmmggn css-42e4bw --parallax pb--60 pt--60" name={"fa9s479u8tv"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19803/ccceae808ad64ff49a72f77274e8f005_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ccceae808ad64ff49a72f77274e8f005_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ccceae808ad64ff49a72f77274e8f005_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ccceae808ad64ff49a72f77274e8f005_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ccceae808ad64ff49a72f77274e8f005_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ccceae808ad64ff49a72f77274e8f005_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ccceae808ad64ff49a72f77274e8f005_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ccceae808ad64ff49a72f77274e8f005_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19803/c1aa7f2788eb437daff022797f5ecb1f_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--48" content={"<span style=\"font-weight: bold; color: var(--white);\">Nový Jičín</span>"}>
              </Title>

              <Text className="text-box fs--26" style={{"maxWidth":467}} content={"<span style=\"color: var(--white); font-weight: bold;\">Adresa: Žerotínova 14 čp. 63\n<br>Tel.: +420 703 979 705</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19803/ac25502ed0b74c2aa0ee4913ef5f7eed_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--48" content={"<span style=\"font-weight: bold; color: var(--white);\">Valašské Klobouky</span>"}>
              </Title>

              <Text className="text-box fs--26" style={{"maxWidth":467}} content={"<span style=\"color: var(--white); font-weight: bold;\">Adresa: Komenského čp. 100/4\n<br>Tel.: +420 703 962 328</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-nub37h --parallax pb--60 pt--60" name={"wupcf7d821g"} layout={"l4"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/f694fefbabb94d9d8a2dc48ec0b875c7_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/7e10b9f0cf3d4da0af7b7331ccfe14b6_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/7e10b9f0cf3d4da0af7b7331ccfe14b6_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19803/7e10b9f0cf3d4da0af7b7331ccfe14b6_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19803/7e10b9f0cf3d4da0af7b7331ccfe14b6_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box fs--48 swpf--uppercase" content={"<span style=\"font-weight: bold; color: var(--white);\">Jakub</span>"}>
              </Text>

              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/19803/c2e1ad28db714b049ce6e101d4869b50_s=1400x_.jpeg 1400w"}>
              </Image>

              <Text className="text-box fs--48 swpf--uppercase" content={"<span style=\"font-weight: bold; color: var(--white);\">Tomáš</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/15190f1d16834c0f99e3b5ee205e2dfd_s=660x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/15190f1d16834c0f99e3b5ee205e2dfd_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19803/15190f1d16834c0f99e3b5ee205e2dfd_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19803/15190f1d16834c0f99e3b5ee205e2dfd_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box ff--1 fs--48 swpf--uppercase" content={"<span style=\"color: var(--white); font-weight: bold;\">Aron</span><br>"}>
              </Text>

              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/798d5044e30c424e9cc0ba48538f5027_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/798d5044e30c424e9cc0ba48538f5027_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/798d5044e30c424e9cc0ba48538f5027_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/798d5044e30c424e9cc0ba48538f5027_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box fs--48 swpf--uppercase" content={"<span style=\"font-weight: bold; color: var(--white);\">Samuel</span>"}>
              </Text>

              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/643f8a8e02a4448cbafce32b5937b491_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/643f8a8e02a4448cbafce32b5937b491_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19803/643f8a8e02a4448cbafce32b5937b491_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19803/643f8a8e02a4448cbafce32b5937b491_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19803/643f8a8e02a4448cbafce32b5937b491_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box fs--48 swpf--uppercase" content={"<span style=\"font-weight: bold; color: var(--white);\">Dominik</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/bb48b91156bb48ba97241c1e1ba684d2_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/bb48b91156bb48ba97241c1e1ba684d2_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19803/bb48b91156bb48ba97241c1e1ba684d2_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19803/bb48b91156bb48ba97241c1e1ba684d2_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box fs--48 swpf--uppercase" content={"<span style=\"color: var(--white); font-weight: bold;\">Patrik</span>"}>
              </Text>

              <Image className="--shape5" src={"https://cdn.swbpg.com/t/19803/9754c7929c55466abb59d71dadbb3cbe_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/19803/9754c7929c55466abb59d71dadbb3cbe_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19803/9754c7929c55466abb59d71dadbb3cbe_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19803/9754c7929c55466abb59d71dadbb3cbe_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box fs--48 swpf--uppercase" content={"<span style=\"font-weight: bold; color: var(--white);\">Oliver</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --right el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--right">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="css-1absx11 --style2" name={"zb0mmh2l4nn"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19803/ed5838e9348a465181ce7260bf387c3d_e=242x393x958x960__s=3000x_.jpeg);
    }
  
    `}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap className="--style2" name={"lcw8spfa4p"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"La Kapo "} style={{"paddingLeft":0}}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Fullmap className="--style2" name={"fvumcftdj6w"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap className="--style2" name={"x9f9hrdotto"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"La Kapo Barbershop Nový Jičín"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}